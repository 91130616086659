import React, { memo } from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb';
import ArchiveSidebar from '../components/ArchiveSidebar/ArchiveSidebar';
import Pagination from '../components/Pagination/Pagination';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import {
  ContentWrapper,
  PageContent,
  StyledH2,
  StyledDate,
  StyledReadMore,
} from './archive.styles';

const archiveTemplate = memo(
  ({
    data: { allWpPost, backgroundImage123 },
    pageContext: { catId, catName, catUri, categories, numPages, currentPage },
  }) => {
    const featuredimage = getImage(backgroundImage123);
    const bgImage = convertToBgImage(featuredimage);

    return (
      <BackgroundImage
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="masthead3"
      >
        <Layout fullMenu>
          <article id="blogpost">
            <header>
              <h2 dangerouslySetInnerHTML={{ __html: catName }} />
              <p>Surge Knowledge Bank</p>
            </header>
            <section className="wrapper style2">
              <div className="inner">
                <BreadCrumb
                  parent={{
                    uri: '/category/knowledge-bank/',
                    title: 'Knowledge',
                  }}
                />
                <ContentWrapper>
                  <ArchiveSidebar catId={catId} categories={categories.edges} />
                  <PageContent>
                    <h1 dangerouslySetInnerHTML={{ __html: catName }} />
                    {allWpPost.edges.map((post) => (
                      <article key={post.node.id} className="entry-content">
                        <Link to={`/knowledge${post.node.uri}`}>
                          <StyledH2
                            dangerouslySetInnerHTML={{
                              __html: post.node.title,
                            }}
                          />
                        </Link>
                        <StyledDate
                          dangerouslySetInnerHTML={{ __html: post.node.date }}
                        />
                        <p
                          dangerouslySetInnerHTML={{
                            __html: post.node.excerpt,
                          }}
                        />
                        <StyledReadMore to={`/knowledge${post.node.uri}`}>
                          More
                        </StyledReadMore>
                        <div className="dot-divider" />
                      </article>
                    ))}
                    <Pagination
                      catUri={catUri}
                      page={currentPage}
                      totalPages={numPages}
                    />
                  </PageContent>
                </ContentWrapper>
              </div>
            </section>
          </article>
        </Layout>
      </BackgroundImage>
    );
  }
);

export default archiveTemplate;

export const pageQuery = graphql`
  query ($catId: String!, $skip: Int!, $limit: Int!) {
    allWpPost(
      filter: { categories: { nodes: { elemMatch: { id: { eq: $catId } } } } }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          title
          excerpt
          uri
          slug
          date(formatString: "DD MM YYYY")
        }
      }
    }
    backgroundImage123: file(relativePath: { eq: "library.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          webpOptions: { quality: 100 }
          height: 1200
        )
      }
    }
  }
`;
